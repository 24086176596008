import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
	requestBlogs: [],
	successfulBlogs: ['payload'],
	errorBlogs: ['error'],
	requestEvents: [],
	successfulEvents: ['payload'],
	errorEvents: ['error'],
});

export const BlogsActionCreators = Creators;

const initialState = {
	loading: false,
	blogs: [],
	events: [],
	error: '',
	message: ''
};

const requestBlogs = (state = initialState) => ({
	...state,
	loading: true,
	error: '',
	blogs: []
});

const successfulBlogs = (state = initialState, action) => {
	const { message, blogs } = action.payload.result;
	return {
		...state,
		loading: false,
		message,
		blogs
	};
};

const errorBlogs = (state = initialState, action) => ({
	...state,
	loading: false,
	error: action.error,
	blogs: null
});
const requestEvents = (state = initialState) => ({
	...state,
	loading: true,
	error: '',
	events: []
});

const successfulEvents = (state = initialState, action) => {
	const { message, events } = action.payload.result;
	return {
		...state,
		loading: false,
		message,
		events
	};
};

const errorEvents = (state = initialState, action) => ({
	...state,
	loading: false,
	error: action.error,
	events: null
});


export default createReducer(initialState, {
	[Types.REQUEST_BLOGS]: requestBlogs,
	[Types.SUCCESSFUL_BLOGS]: successfulBlogs,
	[Types.ERROR_BLOGS]: errorBlogs,
	[Types.REQUEST_EVENTS]: requestEvents,
	[Types.SUCCESSFUL_EVENTS]: successfulEvents,
	[Types.ERROR_EVENTS]: errorEvents,
});
